// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'
import * as AOS from 'aos'
import Swiper from 'swiper/swiper-bundle.min.mjs'

AOS.init();

const aboutSliderSwiper = new Swiper('.about-slider .swiper', {
    effect: 'fade',
    speed: 600,
    autoplay: {
        delay: 5000
    },
    loop: true,
    pagination: {
        el: '.about-slider .swiper .swiper-pagination',
        type: 'bullets',
        clickable: true
    },
})

const licenseSwiper = new Swiper('.license .swiper', {
    effect: 'fade',
    loop: true,
    autoplay: {
        delay: 5000
    },
    on: {
        slideChange: function(swiper){
            //console.log(swiper.realIndex)
            document.querySelectorAll('.license-info').forEach( (slide, i) => {
                if(i !== swiper.realIndex){
                    slide.classList.remove('active')
                } else {
                    slide.classList.add('active')
                }
            })
        }
    }
})

console.log('rdy!')
